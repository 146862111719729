import axios from "axios";

// export const baseURL = `http://192.168.1.9:3001/api`;
export const baseURL = `https://democargo.ebeaconsofts.com/api`; //online Server
// export const baseURL = `http://localhost:3001/api`;
export const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${window.localStorage.getItem("token")}`,
};
const instance = axios.create({
  baseURL: baseURL,
  headers: headers,
  responseType: "json",
});

function createAxiosResponseInterceptor(axiosInstance) {
  const interceptor = axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }
      axiosInstance.interceptors.response.eject(interceptor);
      window.localStorage.clear();
      window.location.replace("/cargo-login");
      return Promise.reject(error);
    }
  );
}

createAxiosResponseInterceptor(instance);

export default instance;
